$phonenumber-text-color: #274E53;
$phonenumber-textbox-height: 64px;
$phonenumber-textbox-width: 460px;

.phonenumber-textbox-container {
  max-width: $phonenumber-textbox-width;
  width: 80%;

  .phonenumber-textbox-element{
    background-color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
    height: $phonenumber-textbox-height;
    position: relative;
    opacity: 0.86;
  }

  .phonenumber-textbox-input {
    border: 0;
    box-sizing: border-box;
    color: $phonenumber-text-color;
    float: left;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400; //Regular
    height: $phonenumber-textbox-height;
    line-height: 28px;
    padding: 15px 0 19px 20px;
    position: relative;
    opacity: 1;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $phonenumber-text-color;
    }
  }

  .phonenumber-textbox-error-message {
    color: red;
    margin-top: 10px;
    margin-left: 5px;
  }

}

@media (min-height: 300px ) and  (max-height: 500px) {
  .phonenumber-textbox-container  {
    .phonenumber-textbox-element{
      height: 34px;
    }
    .phonenumber-textbox-input {
      font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
      line-height: 20px;
      height: 34px;
    }
  }
}
@media (min-height: 500px ) and  (max-height: 900px) {
  .phonenumber-textbox-container  {
    .phonenumber-textbox-element{
      height: 34px;
    }
    .phonenumber-textbox-input {
      font-size: calc(0.95rem + ((1vw - 4.8px) * 0.4167));
      line-height: 20px;
      height: 34px;
      padding: 15px 0 19px 15px;
    }
  }
}
