.image-option-container {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 100%;
  overflow: visible;
}
