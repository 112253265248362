.activity-indicator-container {
  align-content: center;
  align-items: center;
  background-color: #F7CA43;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;

  .activity-container-column {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: auto;
  }

  .activity-indicator-blade {
    animation: blade-fade 3s linear infinite;
    transform-origin: 210px 210px;
  }

  .activity-indicator-message {
    color: #FF9802;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
}
@keyframes blade-fade {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }

  10% {
    opacity: 0.1;
    transform: rotate(45deg);
  }

  20% {
    opacity: 0.2;
    transform: rotate(90deg);
  }

  35% {
    opacity: 0.35;
    transform: rotate(135deg);
  }

  45% {
    opacity: 0.45;
    transform: rotate(180deg);
  }

  65% {
    opacity: 0.65;
    transform: rotate(225deg);
  }

  80% {
    opacity: 0.8;
    transform: rotate(270deg);
  }

  95% {
    opacity: 0.95;
    transform: rotate(315deg);
  }

  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}
