$border-color: #9b9b9b;
$btn-border-color: #274E53;
$btn-highlight-color: #1a6069;
$text-color: #274E53;
$text-highlight-color: #fff;

.chip-item-container {
  align-content: center;
  align-items: center;
  border: 1px solid rgba($border-color, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 82px;
  justify-content: space-between;
  margin: 5px;
  min-height: 82px;
  min-width: 324px;
  width: 324px;

  .chip-item-title {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 18px 0 27px 20px;

    span {
      color: $text-color;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -1px;
    }
  }

  .chip-item-action {
    align-items: flex-end;
    align-content: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 18px 20px 18px 0;

    button {
      background-color: transparent;
      border-radius: 75px;
      border: 2px solid $btn-border-color;
      color: $text-color;
      cursor: pointer;
      display: inline-block;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 14px;
      font-weight: 500; // Medium
      line-height: 21px;
      margin: 0;
      padding: 11px 15px 12px;
      text-align: center;
      text-decoration: none;
      touch-action: manipulation;

      &:hover {
        color: $text-highlight-color;
        background-color: $btn-highlight-color;
        box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
        transform: scale(1.04);
      }

      &:focus {
        outline: none;
      }

      &:active {
        transform: scale(0.99);
      }
    }
  }
}
