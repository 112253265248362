$border-color: #9b9b9b;
$btn-border-color: #274E53;
$btn-highlight-color: #1a6069;
$text-color: #274E53;
$text-highlight-color: #fff;

.list-item-container {
  border: 1px solid rgba($border-color, 0.3);
  display: flex;
  flex-direction: column;
  margin: 5px;
  min-height: 100px;
  max-width: 876px;
  width: 80%;

  .list-item-row {
    display: flex;
    flex-direction: row;
    flex: 1;

    .list-item-title {
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 190px;

      span {
        color: $text-color;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }

    .list-item-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
      margin: 27px 10px 26px 10px;

      span {
        color: rgba($text-color, 0.8);
        flex: 1;
        font-family: "Roboto", Arial, sans-serif;
        font-size: 18px;
        font-weight: 400; //Regular
        line-height: 28px;
      }
    }

    .list-item-action {
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-right: 5px;
      width: 130px;

      .dialog-container {
        button {
          border: 1px solid $btn-border-color;
          border-radius: 100%;
          font-weight: 600;
          margin: 5px 15px 0 0;
          padding: 2px 10px;
        }
      }

      .list-item-info-holder {
        width: 40.72px;
      }

      .list-item-action-button {
        background-color: transparent;
        border-radius: 75px;
        border: 2px solid $btn-border-color;
        color: $text-color;
        cursor: pointer;
        display: inline-block;
        font-family: "Roboto", Arial, sans-serif;
        font-size: 14px;
        font-weight: 500; // Medium
        line-height: 21px;
        margin: 0;
        padding: 11px 15px 12px;
        text-align: center;
        text-decoration: none;
        touch-action: manipulation;

        &:hover {
          color: $text-highlight-color;
          background-color: $btn-highlight-color;
          box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
          transform: scale(1.04);
        }

        &:focus {
          outline: none;
        }

        &:active {
          transform: scale(0.99);
        }
      }
    }

  }
}

@media (min-height: 300px ) and  (max-height: 500px) {
  .list-item-container {
    margin: 2px;
    min-height: 50px;

    .list-item-row {
      .list-item-title {
        width: 130px;

        span {
          font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
          line-height: 20px;
        }
      }
      .list-item-action {
        .dialog-container {
          button {
            margin: 2px 5px 0 0;
            padding: 2px 10px;
          }
        }
        .list-item-action-button {
          font-size: calc(0.75rem + ((1vw - 4.8px) * 0.4167));
          line-height: 18px;
          padding: 7px 10px 8px;
        }
      }
    }
  }
}
@media (min-height: 500px ) and  (max-height: 900px) {
  .list-item-container {
    min-height: 50px;

    .list-item-row {
      .list-item-title {
        width: 150px;

        span {
          font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
          line-height: 20px;
        }
      }
      .list-item-action {
        .dialog-container {
          button {
            margin: 2px 5px 0 0;
            padding: 2px 10px;
          }
        }
        .list-item-action-button {
          font-size: calc(0.75rem + ((1vw - 4.8px) * 0.4167));
          line-height: 18px;
          padding: 7px 10px 8px;
        }
      }
    }
  }
}
