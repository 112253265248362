$text-color: #274E53;

h1 {
  color: $text-color;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 48px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word;
}

// h2 can be modified
h2 {
  color: $text-color;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400; //Regular
  line-height: 28px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word;
}

h3 {
  color: $text-color;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400; //Regular
  line-height: 28px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word;
}

h4 {
  color: rgba($text-color, 0.8);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400; //Regular
  line-height: 28px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word;
}

// h5 can be modified
h5 {
  color: rgba($text-color, 0.8);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400; //Regular
  line-height: 18px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word;
}

// h6 can be modified
h6 {
  color: rgba($text-color, 0.8);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400; //Regular
  line-height: 18px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word;
}

@media (min-width: 180px ) and  (max-width: 480px){
  h1 {
    font-size: calc(1.3rem + ((1vw - 4.8px) * 1.3889));
    letter-spacing: 0;
    line-height: 24px;
    /* Where: * 1.3889 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
  h2, h3 {
    font-size: calc(1rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
  h4 {
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
}
@media (min-width: 480px) and (max-height: 800px) {
  h1 {
    font-size: calc(1.3rem + ((1vw - 4.8px) * 1.3889));
    letter-spacing: 0;
    line-height: 24px;
    /* Where: * 1.3889 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
  h2, h3 {
    font-size: calc(1rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
  h4 {
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
}
@media (min-width: 480px) and (min-height: 800px) {
  h1 {
    font-size: calc(1rem + ((1vw - 4.8px) * 1.3889));
    letter-spacing: 0;
    /* Where: * 1.3889 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
  h2, h3 {
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.4167));
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
  h4 {
    font-size: calc(0.75rem + ((1vw - 4.8px) * 0.4167));
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
}
/* Prevent font scaling beyond this breakpoint */
@media (min-width: 1920px) and (min-height: 800px) {
  h1 {
    font-size: 36px;
  }
  h2, h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
}


