.solar-proposal-container {
  //border: 0.5px solid #274E53;
  margin-top: 45px;
  width: 80%;

  .solar-proposal {
    align-content: flex-start;
    align-items: flex-start;
    background: #F7CA43;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 1px;
    padding: 25px 30px;

    hr {
      border:none;
      height: 20px;
      width: 100%;
      height: 50px;
      margin-top: 0;
      border-bottom: 2px solid #274E53;
      box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
      margin: -50px auto 10px;
    }

    h1 {
      padding: 0;
    }
    .solar-proposal-header {
      margin-bottom: 90px;
      width: 100%;

      img {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        box-shadow: 10px 3px 8px 0 rgba(0, 0, 0, 0.2);
        max-height: 450px;
        object-fit: cover;
        overflow: hidden;
      }

      .header-description {
        background: #F9F3DF;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        box-shadow: 10px 5px 8px 0 rgba(0, 0, 0, 0.2);
        height: 80px;
        margin-top: -5px;
        padding: 15px 30px;

        h1, h3 {
          padding: 0;
          text-align: left;
        }
      }
    }
    .solar-proposal-body {
      align-content: flex-start;
      align-items: flex-start;
      background: #F9F3DF !important;
      border-radius: 25px;
      box-shadow: 8px 10px 8px 0 rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 25px 30px;
      width: 100%;
    }
    .proposal-table {
      margin: 30px 0;
      width: 100%;

      td, th {
        width: 33%;
        text-align: left;
        padding: 8px;
      }
    }
    .proposal-title {
      font-size: 48px;
      margin: 30px 0;
    }
    .proposal-title1 {
      font-size: 38px;
      margin: 30px 0;
    }
    .proposal-section {
      align-content: stretch;
      align-items: stretch;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    .proposal-section-padding {
      padding: 0 30px;
    }
    .proposal-section-bmargin {
      margin: 0 0 30px 0;
    }
    .proposal-section-margin {
      margin: 30px 0 ;
    }
    .proposal-totals {
      border: 3px solid #F7CA43;
    }
    .proposal-card-filled {
      background: #F7CA43;
    }
    .proposal-card-colored {
      h1, h2 {
        color: #F7CA43;
      }
    }
    .proposal-card-border {
      border: 3px solid #F7CA43;
    }
    .proposal-card-full-width {
      width: 100%;
    }
    .proposal-card-padding {
      padding: 20px 0;
    }
    .proposal-card {
      align-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;

      h1, h2 {
        padding: 0 25px;
      }

      h3, h5 {
        font-weight: 600;
        padding: 0;
      }
    }
    .energy-cost-section {
      width: 100%;
    }
  }

  .solar-proposal-footer {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1 {
      padding: 25px 30px;
    }
  }
}

@media (min-width: 300px){
  .solar-proposal-container {
    width: 99%;

    .solar-proposal {
      padding: 25px 5px;
    }
  }
}
@media (min-width: 600px){
  .solar-proposal-container {
    width: 90%;

    .solar-proposal {
      padding: 25px 5px;
    }
  }
}
@media (min-width: 960px) {
  .solar-proposal-container {
    width: 90%;

    .solar-proposal {
      padding: 25px 15px;
    }
  }
}
@media (min-width: 1200px) {
  .solar-proposal-container {
    width: 70%;
  }
}
/* Prevent font scaling beyond this breakpoint */
@media (min-width: 1500px) {
  .solar-proposal-container {
    width: 60%;
  }
}
