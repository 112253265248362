.sun-glowing {
  animation: flicker 1.5s infinite;
}

@keyframes flicker {
  3% { opacity: 1 }
  6% { opacity: .15 }
  9% { opacity: 1 }
  11% { opacity: .35 }
  13% { opacity: 1 }
  22% { opacity: 1 }
  25% { opacity: .25 }
  28% { opacity: 1 }
}
