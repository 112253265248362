.activity-indicator-container {
  align-content: center;
  align-items: center;
  background-color: #F7CA43;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90; }
  .activity-indicator-container .activity-container-column {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: auto; }
  .activity-indicator-container .activity-indicator-blade {
    -webkit-animation: blade-fade 3s linear infinite;
            animation: blade-fade 3s linear infinite;
    transform-origin: 210px 210px; }
  .activity-indicator-container .activity-indicator-message {
    color: #FF9802;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px; }

@-webkit-keyframes blade-fade {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  10% {
    opacity: 0.1;
    transform: rotate(45deg); }
  20% {
    opacity: 0.2;
    transform: rotate(90deg); }
  35% {
    opacity: 0.35;
    transform: rotate(135deg); }
  45% {
    opacity: 0.45;
    transform: rotate(180deg); }
  65% {
    opacity: 0.65;
    transform: rotate(225deg); }
  80% {
    opacity: 0.8;
    transform: rotate(270deg); }
  95% {
    opacity: 0.95;
    transform: rotate(315deg); }
  100% {
    opacity: 1;
    transform: rotate(360deg); } }

@keyframes blade-fade {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  10% {
    opacity: 0.1;
    transform: rotate(45deg); }
  20% {
    opacity: 0.2;
    transform: rotate(90deg); }
  35% {
    opacity: 0.35;
    transform: rotate(135deg); }
  45% {
    opacity: 0.45;
    transform: rotate(180deg); }
  65% {
    opacity: 0.65;
    transform: rotate(225deg); }
  80% {
    opacity: 0.8;
    transform: rotate(270deg); }
  95% {
    opacity: 0.95;
    transform: rotate(315deg); }
  100% {
    opacity: 1;
    transform: rotate(360deg); } }

.nocontent-container {
  align-content: center;
  align-items: center;
  background-color: #F7CA43;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  overflow: auto; }
  .nocontent-container .nocontent-message {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-height: 100%;
    overflow: auto; }
    .nocontent-container .nocontent-message button {
      background-color: transparent;
      border-radius: 75px;
      border: 2px solid #274E53;
      color: #274E53;
      cursor: pointer;
      display: inline-block;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin: 25px;
      padding: 11px 19px 12px;
      text-align: center;
      text-decoration: none;
      touch-action: manipulation; }
      .nocontent-container .nocontent-message button:hover {
        background-color: #fffbf0;
        box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
        transform: scale(1.04); }
      .nocontent-container .nocontent-message button:focus {
        outline: none; }
      .nocontent-container .nocontent-message button:active {
        transform: scale(0.99); }

.address-textbox-container {
  display: flex;
  justify-content: center;
  width: 100%; }
  .address-textbox-container .search-container {
    max-width: 460px;
    width: 80%; }
    .address-textbox-container .search-container .search-box {
      background-color: #ffffff;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
      height: 64px;
      position: relative;
      opacity: 0.86; }
    .address-textbox-container .search-container .search-icon {
      background: transparent;
      border: none;
      cursor: pointer;
      float: right;
      height: 64px;
      padding: 17px 20px 23px 0;
      position: absolute;
      right: 2px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 52px; }
      .address-textbox-container .search-container .search-icon:active {
        transform: scale(0.99); }
    .address-textbox-container .search-container .search-input {
      border: 0;
      box-sizing: border-box;
      color: #274E53;
      display: block;
      float: left;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 20px;
      font-weight: 400;
      height: 64px;
      line-height: 28px;
      padding: 15px 0 19px 20px;
      position: relative;
      opacity: 1;
      width: 100%; }
      .address-textbox-container .search-container .search-input:focus {
        outline: none; }
      .address-textbox-container .search-container .search-input::-webkit-input-placeholder {
        color: #274E53; }
      .address-textbox-container .search-container .search-input::-ms-input-placeholder {
        color: #274E53; }
      .address-textbox-container .search-container .search-input::placeholder {
        color: #274E53; }
    .address-textbox-container .search-container .search-input-autocomplete {
      border-bottom: rgba(255, 255, 255, 0.3);
      border-left: rgba(255, 255, 255, 0.3);
      border-right: rgba(255, 255, 255, 0.3);
      border-top: 1px solid #e6e6e6;
      box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
      border-radius: 0 0 2px 2px; }
      .address-textbox-container .search-container .search-input-autocomplete .autocomplete-item {
        text-align: left;
        background-color: #ffffff;
        cursor: pointer; }
      .address-textbox-container .search-container .search-input-autocomplete .autocomplete-item-active {
        background-color: #dedede !important; }
  .address-textbox-container .searchbox-error-message {
    color: red;
    margin-top: 10px;
    margin-left: 5px; }

@media (min-height: 300px) and (max-height: 500px) {
  .address-textbox-container .search-container .search-box {
    height: 34px; }
  .address-textbox-container .search-container .search-input {
    font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px; } }

@media (min-height: 500px) and (max-height: 900px) {
  .address-textbox-container .search-container .search-box {
    height: 34px; }
  .address-textbox-container .search-container .search-input {
    font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px; } }

.rc-dialog-mask {
  background-color: #274e53;
  opacity: 0.75; }

.solar-report-center {
  display: flex;
  align-items: center;
  justify-content: center; }
  .solar-report-center > .rc-dialog {
    height: 90%;
    margin: 30px auto; }
    .solar-report-center > .rc-dialog > .rc-dialog-content {
      background-color: transparent;
      height: 100%; }
      .solar-report-center > .rc-dialog > .rc-dialog-content > .rc-dialog-close {
        color: white;
        right: -25px;
        top: -5px; }
      .solar-report-center > .rc-dialog > .rc-dialog-content > .rc-dialog-body {
        height: 100%;
        padding: 0;
        opacity: 1; }

.solar-report-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center; }
  .solar-report-container button {
    background-color: transparent;
    border-radius: 56px;
    border: 2px solid #274E53;
    box-shadow: 0 2px 4px rgba(39, 78, 83, 0.52);
    color: #274E53;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 11px;
    font-weight: 500;
    float: right;
    line-height: 15.75px;
    margin: 30px;
    padding: 15px 28px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation; }
    .solar-report-container button:hover {
      background-color: #fffbf0;
      box-shadow: 0 4px 8px rgba(39, 78, 83, 0.52);
      transform: scale(1.04); }
    .solar-report-container button:focus {
      outline: none; }
    .solar-report-container button:active {
      transform: scale(0.99); }

button.page-back-button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  color: #274E53;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none; }
  button.page-back-button .svg-icon path {
    fill: #274E53; }
  button.page-back-button .back-title {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px; }
  button.page-back-button .back-subtitle {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600; }
  button.page-back-button:hover {
    color: #1a6069;
    transform: scale(1.05); }
    button.page-back-button:hover .svg-icon path {
      fill: #1a6069; }
  button.page-back-button:focus {
    outline: none; }
  button.page-back-button:active {
    transform: scale(0.99); }

h1 {
  color: #274E53;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 48px;
  margin: 0; }

h3 {
  color: #274E53;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0; }

h4 {
  color: rgba(39, 78, 83, 0.8);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin: 0; }

.button-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center; }
  .button-container button {
    background-color: transparent;
    border-radius: 75px;
    border: 2px solid #274E53;
    color: #274E53;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 0;
    padding: 11px 19px 12px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation; }
    .button-container button.btn-primary {
      background-color: #274E53;
      border-radius: 100px;
      box-shadow: 0 2px 4px rgba(39, 78, 83, 0.52);
      color: #ffffff;
      font-size: 18px;
      line-height: 28px;
      padding: 15px 28px; }
    .button-container button.btn-secondary {
      background-color: transparent; }
      .button-container button.btn-secondary:hover {
        background-color: #fffbf0;
        box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
        transform: scale(1.04); }
    .button-container button:hover {
      background-color: #1a6069;
      box-shadow: 0 4px 8px rgba(39, 78, 83, 0.52);
      transform: scale(1.04); }
    .button-container button:focus {
      outline: none; }
    .button-container button:active {
      transform: scale(0.99); }

@media (min-height: 300px) and (max-height: 500px) {
  .button-container button {
    padding: 5px 15px 6px;
    margin: 5px; }
    .button-container button.btn-primary {
      padding: 5px 15px; }
    .button-container button.btn-secondary {
      padding: 5px 15px; } }

@media (min-height: 500px) and (max-height: 800px) {
  .button-container button {
    padding: 5px 15px 6px;
    margin: 5px; }
    .button-container button.btn-primary {
      padding: 8px 20px; }
    .button-container button.btn-secondary {
      padding: 8px 20px; } }

.bypass-button-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center; }
  .bypass-button-container button {
    background-color: transparent;
    border-radius: 75px;
    border: 2px solid #274E53;
    color: #274E53;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 0;
    padding: 11px 19px 12px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation; }
    .bypass-button-container button:hover {
      background-color: #fffbf0;
      box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
      transform: scale(1.04); }
    .bypass-button-container button:focus {
      outline: none; }
    .bypass-button-container button:active {
      transform: scale(0.99); }

.chip-component-container {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  overflow: auto; }

.chip-item-container {
  align-content: center;
  align-items: center;
  border: 1px solid rgba(155, 155, 155, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 82px;
  justify-content: space-between;
  margin: 5px;
  min-height: 82px;
  min-width: 324px;
  width: 324px; }
  .chip-item-container .chip-item-title {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 18px 0 27px 20px; }
    .chip-item-container .chip-item-title span {
      color: #274E53;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -1px; }
  .chip-item-container .chip-item-action {
    align-items: flex-end;
    align-content: flex-end;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 18px 20px 18px 0; }
    .chip-item-container .chip-item-action button {
      background-color: transparent;
      border-radius: 75px;
      border: 2px solid #274E53;
      color: #274E53;
      cursor: pointer;
      display: inline-block;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin: 0;
      padding: 11px 15px 12px;
      text-align: center;
      text-decoration: none;
      touch-action: manipulation; }
      .chip-item-container .chip-item-action button:hover {
        color: #fff;
        background-color: #1a6069;
        box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
        transform: scale(1.04); }
      .chip-item-container .chip-item-action button:focus {
        outline: none; }
      .chip-item-container .chip-item-action button:active {
        transform: scale(0.99); }

.contact-container {
  align-content: center;
  align-items: center;
  border: 1px solid rgba(155, 155, 155, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 144px;
  justify-content: center;
  margin: 9px;
  width: 418px; }
  .contact-container .contact-title {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 36px;
    margin-bottom: 13px; }
  .contact-container .contact-email {
    color: #274E53;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px; }
  .contact-container .contact-phone {
    color: #274E53;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px; }
  .contact-container:hover {
    transform: scale(1.04); }

.dialog-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.rc-dialog-mask {
  background-color: #274e53;
  opacity: 0.75; }

.rc-dialog-content {
  background-color: #F9F3DF; }
  .rc-dialog-content .rc-dialog-close {
    color: #274E53; }
  .rc-dialog-content .rc-dialog-body {
    color: #274E53;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 6px 30px 5px;
    opacity: 0.8; }

.rc-dialog-header {
  background: #F9F3DF;
  border-bottom: none;
  padding: 9px 30px 0; }
  .rc-dialog-header .rc-dialog-title {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1.33px;
    line-height: 48px; }

.rc-dialog-footer {
  border-top: none;
  height: 45px; }
  .rc-dialog-footer button {
    background-color: transparent;
    border-radius: 56px;
    border: 2px solid #274E53;
    color: #274E53;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 11px;
    font-weight: 500;
    float: right;
    line-height: 15.75px;
    margin-right: 10px;
    padding: 8px 16px 10px 15px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation; }
    .rc-dialog-footer button:hover {
      background-color: #fffbf0;
      box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
      transform: scale(1.04); }
    .rc-dialog-footer button:focus {
      outline: none; }
    .rc-dialog-footer button:active {
      transform: scale(0.99); }

.dialog-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center; }
  .dialog-container button {
    background-color: transparent;
    border-radius: 75px;
    border: 2px solid #274E53;
    color: #274E53;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 30px;
    padding: 11px 19px 12px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation; }
    .dialog-container button:hover {
      background-color: #fffbf0;
      box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
      transform: scale(1.04); }
    .dialog-container button:focus {
      outline: none; }
    .dialog-container button:active {
      transform: scale(0.99); }

@media (max-width: 600px) {
  .dialog-container button {
    margin: 3px 0 5px; } }

@media (min-height: 300px) and (max-height: 500px) {
  .dialog-container button {
    padding: 5px 15px 6px;
    margin: 5px; } }

@media (min-height: 500px) and (max-height: 800px) {
  .dialog-container button {
    padding: 5px 15px 6px;
    margin: 5px; } }

.email-textbox-container {
  max-width: 460px;
  width: 80%; }
  .email-textbox-container .email-textbox-element {
    background-color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
    height: 64px;
    position: relative;
    opacity: 0.86; }
  .email-textbox-container .email-textbox-input {
    border: 0;
    box-sizing: border-box;
    color: #274E53;
    float: left;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    height: 64px;
    line-height: 28px;
    padding: 15px 0 19px 20px;
    position: relative;
    opacity: 1;
    width: 100%; }
    .email-textbox-container .email-textbox-input:focus {
      outline: none; }
    .email-textbox-container .email-textbox-input::-webkit-input-placeholder {
      color: #274E53; }
    .email-textbox-container .email-textbox-input::-ms-input-placeholder {
      color: #274E53; }
    .email-textbox-container .email-textbox-input::placeholder {
      color: #274E53; }

@media (min-height: 300px) and (max-height: 500px) {
  .email-textbox-container .email-textbox-element {
    height: 34px; }
  .email-textbox-container .email-textbox-input {
    font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px; } }

@media (min-height: 500px) and (max-height: 900px) {
  .email-textbox-container .email-textbox-element {
    height: 34px; }
  .email-textbox-container .email-textbox-input {
    font-size: calc(0.95rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px;
    padding: 15px 0 19px 15px; } }


.image-component-container {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center; }

.image-component-asset {
  object-fit: fill;
  overflow: hidden;
  height: 100%; }

.image-container-component {
  box-sizing: border-box;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%; }
  .image-container-component .image-container-component-before {
    background-image: none;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .image-container-component .image-container-component-after {
    background-color: #1B1B1B;
    opacity: 0.6;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .image-container-component .image-container-component-children {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    min-width: 100%;
    position: relative;
    z-index: 3; }

.image-option-container {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 100%;
  overflow: visible; }

h1 {
  color: #274E53;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 48px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word; }

h2 {
  color: #274E53;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word; }

h3 {
  color: #274E53;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word; }

h4 {
  color: rgba(39, 78, 83, 0.8);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word; }

h5 {
  color: rgba(39, 78, 83, 0.8);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word; }

h6 {
  color: rgba(39, 78, 83, 0.8);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  padding: 0 25px;
  text-align: center;
  word-wrap: break-word; }

@media (min-width: 180px) and (max-width: 480px) {
  h1 {
    font-size: calc(1.3rem + ((1vw - 4.8px) * 1.3889));
    letter-spacing: 0;
    line-height: 24px;
    /* Where: * 1.3889 = 100 * font_Size_Difference / viewport_Width_Difference */ }
  h2, h3 {
    font-size: calc(1rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */ }
  h4 {
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */ } }

@media (min-width: 480px) and (max-height: 800px) {
  h1 {
    font-size: calc(1.3rem + ((1vw - 4.8px) * 1.3889));
    letter-spacing: 0;
    line-height: 24px;
    /* Where: * 1.3889 = 100 * font_Size_Difference / viewport_Width_Difference */ }
  h2, h3 {
    font-size: calc(1rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */ }
  h4 {
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */ } }

@media (min-width: 480px) and (min-height: 800px) {
  h1 {
    font-size: calc(1rem + ((1vw - 4.8px) * 1.3889));
    letter-spacing: 0;
    /* Where: * 1.3889 = 100 * font_Size_Difference / viewport_Width_Difference */ }
  h2, h3 {
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.4167));
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */ }
  h4 {
    font-size: calc(0.75rem + ((1vw - 4.8px) * 0.4167));
    /* Where: * 0.4167 = 100 * font_Size_Difference / viewport_Width_Difference */ } }

/* Prevent font scaling beyond this breakpoint */
@media (min-width: 1920px) and (min-height: 800px) {
  h1 {
    font-size: 36px; }
  h2, h3 {
    font-size: 20px; }
  h4 {
    font-size: 18px; } }

.list-item-container {
  border: 1px solid rgba(155, 155, 155, 0.3);
  display: flex;
  flex-direction: column;
  margin: 5px;
  min-height: 100px;
  max-width: 876px;
  width: 80%; }
  .list-item-container .list-item-row {
    display: flex;
    flex-direction: row;
    flex: 1 1; }
    .list-item-container .list-item-row .list-item-title {
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 190px; }
      .list-item-container .list-item-row .list-item-title span {
        color: #274E53;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px; }
    .list-item-container .list-item-row .list-item-content {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      justify-content: flex-start;
      margin: 27px 10px 26px 10px; }
      .list-item-container .list-item-row .list-item-content span {
        color: rgba(39, 78, 83, 0.8);
        flex: 1 1;
        font-family: "Roboto", Arial, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px; }
    .list-item-container .list-item-row .list-item-action {
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-right: 5px;
      width: 130px; }
      .list-item-container .list-item-row .list-item-action .dialog-container button {
        border: 1px solid #274E53;
        border-radius: 100%;
        font-weight: 600;
        margin: 5px 15px 0 0;
        padding: 2px 10px; }
      .list-item-container .list-item-row .list-item-action .list-item-info-holder {
        width: 40.72px; }
      .list-item-container .list-item-row .list-item-action .list-item-action-button {
        background-color: transparent;
        border-radius: 75px;
        border: 2px solid #274E53;
        color: #274E53;
        cursor: pointer;
        display: inline-block;
        font-family: "Roboto", Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin: 0;
        padding: 11px 15px 12px;
        text-align: center;
        text-decoration: none;
        touch-action: manipulation; }
        .list-item-container .list-item-row .list-item-action .list-item-action-button:hover {
          color: #fff;
          background-color: #1a6069;
          box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
          transform: scale(1.04); }
        .list-item-container .list-item-row .list-item-action .list-item-action-button:focus {
          outline: none; }
        .list-item-container .list-item-row .list-item-action .list-item-action-button:active {
          transform: scale(0.99); }

@media (min-height: 300px) and (max-height: 500px) {
  .list-item-container {
    margin: 2px;
    min-height: 50px; }
    .list-item-container .list-item-row .list-item-title {
      width: 130px; }
      .list-item-container .list-item-row .list-item-title span {
        font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
        line-height: 20px; }
    .list-item-container .list-item-row .list-item-action .dialog-container button {
      margin: 2px 5px 0 0;
      padding: 2px 10px; }
    .list-item-container .list-item-row .list-item-action .list-item-action-button {
      font-size: calc(0.75rem + ((1vw - 4.8px) * 0.4167));
      line-height: 18px;
      padding: 7px 10px 8px; } }

@media (min-height: 500px) and (max-height: 900px) {
  .list-item-container {
    min-height: 50px; }
    .list-item-container .list-item-row .list-item-title {
      width: 150px; }
      .list-item-container .list-item-row .list-item-title span {
        font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
        line-height: 20px; }
    .list-item-container .list-item-row .list-item-action .dialog-container button {
      margin: 2px 5px 0 0;
      padding: 2px 10px; }
    .list-item-container .list-item-row .list-item-action .list-item-action-button {
      font-size: calc(0.75rem + ((1vw - 4.8px) * 0.4167));
      line-height: 18px;
      padding: 7px 10px 8px; } }

.map-annotation-container {
  display: flex;
  flex: 1 1 auto;
  position: relative; }
  .map-annotation-container .map-annotation-dot {
    margin-top: 300px;
    background-color: #F7CA43;
    border-radius: 50%;
    box-shadow: 0 0 0 5px #fff, 0 0 0 8px rgba(247, 202, 67, 0.3);
    display: inline-block;
    height: 20px;
    width: 20px; }
  .map-annotation-container .map-annotation-text {
    margin-top: 300px;
    background-color: rgba(27, 27, 27, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    bottom: 170%;
    color: #fff;
    left: 50%;
    margin-left: -60px;
    padding: 5px 0;
    position: absolute;
    text-align: center;
    width: 120px;
    z-index: 8; }
    .map-annotation-container .map-annotation-text::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border: 5px solid transparent;
      border-top-color: rgba(27, 27, 27, 0.5); }

.helperText {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .helperText img {
    width: 150px;
    height: auto;
    margin-bottom: 30px; }
  .helperText h3 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin: 0; }

.googlemap-container {
  height: 100%;
  width: 100%; }

.googlemap-container {
  height: 100%;
  width: 100%; }

.street-view-container {
  position: relative;
  left: calc(100% / 2 - (90%/2));
  top: calc(-1 * (100vh / 2 + 225px));
  z-index: 3; }
  .street-view-container #street-view-container {
    border-radius: 15px;
    height: 450px;
    position: absolute;
    width: 90%; }

.image-option-item button {
  background-color: transparent;
  border: 4px solid rgba(39, 78, 83, 0.14);
  border-radius: 70px;
  color: #274E53;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 38px;
  font-weight: 600;
  height: 141px;
  line-height: 52px;
  margin: 10px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  width: 140px; }
  .image-option-item button:hover {
    background-color: #f7ca43;
    border: 4px solid #274E53;
    transform: scale(1.04); }
  .image-option-item button:focus {
    outline: none; }
  .image-option-item button:active {
    border: 4px solid #274E53;
    transform: scale(0.99); }

@media (min-width: 180px) and (max-width: 480px) {
  .image-option-item button {
    height: 70px;
    width: 70px; } }

@media (min-width: 480px) and (max-height: 800px) {
  .image-option-item button {
    height: 80px;
    width: 80px; } }

@media (min-width: 480px) and (min-height: 800px) {
  .image-option-item button {
    height: 120px;
    width: 120px; } }

.page-title-container {
  display: flex;
  position: relative;
  width: 100%; }
  .page-title-container .page-title {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1.33px;
    padding: 15px 0 0 30px; }
  .page-title-container .page-back-btn-container {
    align-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px; }
    .page-title-container .page-back-btn-container button {
      border: none;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0);
      color: #274E53;
      cursor: pointer;
      font-family: sans-serif;
      font-size: 1rem;
      line-height: 1;
      margin-left: -95px;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .page-title-container .page-back-btn-container button .svg-icon path {
        fill: #274E53; }
      .page-title-container .page-back-btn-container button:hover {
        color: #1a6069;
        transform: scale(1.05); }
        .page-title-container .page-back-btn-container button:hover .svg-icon path {
          fill: #1a6069; }
      .page-title-container .page-back-btn-container button:focus {
        outline: none; }
      .page-title-container .page-back-btn-container button:active {
        transform: scale(0.99); }

.page-title-logo-container {
  display: flex;
  margin: 15px 0 0 30px;
  position: relative;
  width: 100%; }
  .page-title-logo-container .page-title-logo {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1.33px; }
  .page-title-logo-container .page-back-btn-container {
    align-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: center; }
    .page-title-logo-container .page-back-btn-container button {
      border: none;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0);
      color: #274E53;
      cursor: pointer;
      font-family: sans-serif;
      font-size: 1rem;
      line-height: 1;
      margin-left: -95px;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .page-title-logo-container .page-back-btn-container button .svg-icon path {
        fill: #274E53; }
      .page-title-logo-container .page-back-btn-container button:hover {
        color: #1a6069;
        transform: scale(1.05); }
        .page-title-logo-container .page-back-btn-container button:hover .svg-icon path {
          fill: #1a6069; }
      .page-title-logo-container .page-back-btn-container button:focus {
        outline: none; }
      .page-title-logo-container .page-back-btn-container button:active {
        transform: scale(0.99); }

.phonenumber-textbox-container {
  max-width: 460px;
  width: 80%; }
  .phonenumber-textbox-container .phonenumber-textbox-element {
    background-color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
    height: 64px;
    position: relative;
    opacity: 0.86; }
  .phonenumber-textbox-container .phonenumber-textbox-input {
    border: 0;
    box-sizing: border-box;
    color: #274E53;
    float: left;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    height: 64px;
    line-height: 28px;
    padding: 15px 0 19px 20px;
    position: relative;
    opacity: 1;
    width: 100%; }
    .phonenumber-textbox-container .phonenumber-textbox-input:focus {
      outline: none; }
    .phonenumber-textbox-container .phonenumber-textbox-input::-webkit-input-placeholder {
      color: #274E53; }
    .phonenumber-textbox-container .phonenumber-textbox-input::-ms-input-placeholder {
      color: #274E53; }
    .phonenumber-textbox-container .phonenumber-textbox-input::placeholder {
      color: #274E53; }
  .phonenumber-textbox-container .phonenumber-textbox-error-message {
    color: red;
    margin-top: 10px;
    margin-left: 5px; }

@media (min-height: 300px) and (max-height: 500px) {
  .phonenumber-textbox-container .phonenumber-textbox-element {
    height: 34px; }
  .phonenumber-textbox-container .phonenumber-textbox-input {
    font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px; } }

@media (min-height: 500px) and (max-height: 900px) {
  .phonenumber-textbox-container .phonenumber-textbox-element {
    height: 34px; }
  .phonenumber-textbox-container .phonenumber-textbox-input {
    font-size: calc(0.95rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px;
    padding: 15px 0 19px 15px; } }

.slider-container {
  margin-top: 20px;
  max-width: 460px;
  width: 80%; }
  .slider-container h3 {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -1.85px;
    line-height: 58px;
    margin: 20px 0 24px;
    text-align: center; }
  .slider-container .rc-slider-mark {
    font-size: 16px; }
  .slider-container .rc-slider-rail {
    background-color: #43484d;
    opacity: 0.1; }
  .slider-container .rc-slider-track {
    background-color: #ffffff; }
  .slider-container .rc-slider-handle {
    background-color: #274e53;
    border: 6px solid #ffffff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.17);
    height: 30px;
    margin-top: -13px;
    width: 30px; }

@media (max-width: 600px) {
  .slider-container h3 {
    font-size: 36px;
    letter-spacing: -1.38px;
    line-height: 48px;
    margin: 20px 0 10px; } }

@media (min-width: 1025px) and (max-width: 1280px) {
  .slider-container h3 {
    font-size: 28px;
    letter-spacing: -1.38px;
    line-height: 34px;
    margin: 20px 0 10px; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .slider-container h3 {
    font-size: 21px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 10px 0 10px; } }

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .slider-container h3 {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 15px 0 10px; } }

@media (min-width: 481px) and (max-width: 767px) {
  .slider-container h3 {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 10px 0 10px; } }

@media (min-width: 320px) and (max-width: 480px) {
  .slider-container h3 {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    margin: 10px 0 5px; } }

.sun-glowing {
  -webkit-animation: flicker 1.5s infinite;
          animation: flicker 1.5s infinite; }

@-webkit-keyframes flicker {
  3% {
    opacity: 1; }
  6% {
    opacity: .15; }
  9% {
    opacity: 1; }
  11% {
    opacity: .35; }
  13% {
    opacity: 1; }
  22% {
    opacity: 1; }
  25% {
    opacity: .25; }
  28% {
    opacity: 1; } }

@keyframes flicker {
  3% {
    opacity: 1; }
  6% {
    opacity: .15; }
  9% {
    opacity: 1; }
  11% {
    opacity: .35; }
  13% {
    opacity: 1; }
  22% {
    opacity: 1; }
  25% {
    opacity: .25; }
  28% {
    opacity: 1; } }

.solar-proposal-container {
  margin-top: 45px;
  width: 80%; }
  .solar-proposal-container .solar-proposal {
    align-content: flex-start;
    align-items: flex-start;
    background: #F7CA43;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 1px;
    padding: 25px 30px; }
    .solar-proposal-container .solar-proposal hr {
      border: none;
      height: 20px;
      width: 100%;
      height: 50px;
      margin-top: 0;
      border-bottom: 2px solid #274E53;
      box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
      margin: -50px auto 10px; }
    .solar-proposal-container .solar-proposal h1 {
      padding: 0; }
    .solar-proposal-container .solar-proposal .solar-proposal-header {
      margin-bottom: 90px;
      width: 100%; }
      .solar-proposal-container .solar-proposal .solar-proposal-header img {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        box-shadow: 10px 3px 8px 0 rgba(0, 0, 0, 0.2);
        max-height: 450px;
        object-fit: cover;
        overflow: hidden; }
      .solar-proposal-container .solar-proposal .solar-proposal-header .header-description {
        background: #F9F3DF;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        box-shadow: 10px 5px 8px 0 rgba(0, 0, 0, 0.2);
        height: 80px;
        margin-top: -5px;
        padding: 15px 30px; }
        .solar-proposal-container .solar-proposal .solar-proposal-header .header-description h1, .solar-proposal-container .solar-proposal .solar-proposal-header .header-description h3 {
          padding: 0;
          text-align: left; }
    .solar-proposal-container .solar-proposal .solar-proposal-body {
      align-content: flex-start;
      align-items: flex-start;
      background: #F9F3DF !important;
      border-radius: 25px;
      box-shadow: 8px 10px 8px 0 rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 25px 30px;
      width: 100%; }
    .solar-proposal-container .solar-proposal .proposal-table {
      margin: 30px 0;
      width: 100%; }
      .solar-proposal-container .solar-proposal .proposal-table td, .solar-proposal-container .solar-proposal .proposal-table th {
        width: 33%;
        text-align: left;
        padding: 8px; }
    .solar-proposal-container .solar-proposal .proposal-title {
      font-size: 48px;
      margin: 30px 0; }
    .solar-proposal-container .solar-proposal .proposal-title1 {
      font-size: 38px;
      margin: 30px 0; }
    .solar-proposal-container .solar-proposal .proposal-section {
      align-content: stretch;
      align-items: stretch;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%; }
    .solar-proposal-container .solar-proposal .proposal-section-padding {
      padding: 0 30px; }
    .solar-proposal-container .solar-proposal .proposal-section-bmargin {
      margin: 0 0 30px 0; }
    .solar-proposal-container .solar-proposal .proposal-section-margin {
      margin: 30px 0; }
    .solar-proposal-container .solar-proposal .proposal-totals {
      border: 3px solid #F7CA43; }
    .solar-proposal-container .solar-proposal .proposal-card-filled {
      background: #F7CA43; }
    .solar-proposal-container .solar-proposal .proposal-card-colored h1, .solar-proposal-container .solar-proposal .proposal-card-colored h2 {
      color: #F7CA43; }
    .solar-proposal-container .solar-proposal .proposal-card-border {
      border: 3px solid #F7CA43; }
    .solar-proposal-container .solar-proposal .proposal-card-full-width {
      width: 100%; }
    .solar-proposal-container .solar-proposal .proposal-card-padding {
      padding: 20px 0; }
    .solar-proposal-container .solar-proposal .proposal-card {
      align-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%; }
      .solar-proposal-container .solar-proposal .proposal-card h1, .solar-proposal-container .solar-proposal .proposal-card h2 {
        padding: 0 25px; }
      .solar-proposal-container .solar-proposal .proposal-card h3, .solar-proposal-container .solar-proposal .proposal-card h5 {
        font-weight: 600;
        padding: 0; }
    .solar-proposal-container .solar-proposal .energy-cost-section {
      width: 100%; }
  .solar-proposal-container .solar-proposal-footer {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .solar-proposal-container .solar-proposal-footer h1 {
      padding: 25px 30px; }

@media (min-width: 300px) {
  .solar-proposal-container {
    width: 99%; }
    .solar-proposal-container .solar-proposal {
      padding: 25px 5px; } }

@media (min-width: 600px) {
  .solar-proposal-container {
    width: 90%; }
    .solar-proposal-container .solar-proposal {
      padding: 25px 5px; } }

@media (min-width: 960px) {
  .solar-proposal-container {
    width: 90%; }
    .solar-proposal-container .solar-proposal {
      padding: 25px 15px; } }

@media (min-width: 1200px) {
  .solar-proposal-container {
    width: 70%; } }

/* Prevent font scaling beyond this breakpoint */
@media (min-width: 1500px) {
  .solar-proposal-container {
    width: 60%; } }

.textbox-container {
  max-width: 460px;
  width: 80%; }
  .textbox-container .textbox-element {
    background-color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
    height: 64px;
    position: relative;
    opacity: 0.86; }
    .textbox-container .textbox-element.error {
      border-color: red; }
  .textbox-container .textbox-icon {
    background-color: inherit;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    float: right;
    height: 64px;
    padding: 17px 20px 23px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 52px; }
    .textbox-container .textbox-icon:active {
      transform: scale(0.99); }
  .textbox-container .textbox-input {
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    color: #274E53;
    float: left;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    height: 64px;
    line-height: 28px;
    padding: 15px 0 19px 20px;
    position: relative;
    opacity: 1;
    width: 388px; }
    .textbox-container .textbox-input:focus {
      outline: none; }
    .textbox-container .textbox-input::-webkit-input-placeholder {
      color: #274E53; }
    .textbox-container .textbox-input::-ms-input-placeholder {
      color: #274E53; }
    .textbox-container .textbox-input::placeholder {
      color: #274E53; }
  .textbox-container .textbox-error-message {
    color: red;
    margin-top: 10px;
    margin-left: 5px; }

@media (min-height: 300px) and (max-height: 500px) {
  .textbox-container .textbox-element {
    height: 34px; }
  .textbox-container .textbox-input {
    font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px; } }

@media (min-height: 500px) and (max-height: 900px) {
  .textbox-container .textbox-element {
    height: 34px; }
  .textbox-container .textbox-input {
    font-size: calc(0.95rem + ((1vw - 4.8px) * 0.4167));
    line-height: 20px;
    height: 34px;
    padding: 15px 0 19px 15px; } }

.rc-dialog-mask {
  background-color: #274e53;
  opacity: 0.75; }

.engine-center {
  display: flex;
  align-items: center;
  justify-content: center; }
  .engine-center > .rc-dialog {
    width: 98%;
    height: 97%;
    margin: 30px auto; }
    .engine-center > .rc-dialog > .rc-dialog-content {
      height: 100%; }
      .engine-center > .rc-dialog > .rc-dialog-content > .rc-dialog-close {
        color: #ffffff;
        right: 5px;
        top: 5px;
        opacity: 1;
        z-index: 99; }
      .engine-center > .rc-dialog > .rc-dialog-content > .rc-dialog-body {
        height: 100%;
        padding: 0;
        opacity: 1; }
        .engine-center > .rc-dialog > .rc-dialog-content > .rc-dialog-body .googlemap-container {
          height: 100%;
          width: 100%; }

.engine-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center; }
  .engine-container button {
    background-color: #274E53;
    border-color: transparent;
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(39, 78, 83, 0.52);
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: 30px;
    padding: 15px 28px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation; }
    .engine-container button:hover {
      background-color: #1a6069;
      box-shadow: 0 4px 8px rgba(39, 78, 83, 0.52);
      transform: scale(1.04); }
    .engine-container button:focus {
      outline: none; }
    .engine-container button:active {
      transform: scale(0.99); }

@media (max-width: 600px) {
  .engine-center > .rc-dialog {
    width: 100%;
    height: 100%; }
    .engine-center > .rc-dialog > .rc-dialog-content {
      border-radius: 0; }
      .engine-center > .rc-dialog > .rc-dialog-content > .rc-dialog-close {
        color: #757575;
        top: 10px; }
      .engine-center > .rc-dialog > .rc-dialog-content > .rc-dialog-body .googlemap-container {
        height: 100%;
        width: 100vw; } }

.article-image-component {
  height: 500px;
  margin: 15px 50px;
  width: calc(100% - 100px); }
  .article-image-component .article-image-asset {
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 100%; }

@media (max-width: 1024px) {
  .article-image-component {
    height: 350px;
    margin: 15px 50px;
    width: calc(100% - 100px); } }

@media (max-width: 600px) {
  .article-image-component {
    height: 200px;
    margin: 15px 50px;
    width: calc(100% - 100px); } }

.article-header-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 400px;
  width: 100%;
  position: relative; }
  .article-header-container .article-header-text-before {
    background-image: none;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .article-header-container .article-header-text-after {
    background-image: linear-gradient(to right, #1b1b1b, rgba(0, 0, 0, 0));
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .article-header-container .article-header-text {
    min-width: 100%;
    position: relative;
    z-index: 3; }
    .article-header-container .article-header-text .page-title-logo-container {
      margin: 40px 0 0 40px; }
    .article-header-container .article-header-text .article-header-date {
      color: #ffffff;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      margin: 50px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word; }
    .article-header-container .article-header-text .article-header-title {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -2px;
      line-height: 48px;
      margin: 30px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word; }
    .article-header-container .article-header-text .article-header-brand {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: -1.33px;
      line-height: 33px;
      margin: 20px 0 5px 15px;
      padding: 0 25px 30px;
      width: 40%;
      text-align: start;
      word-wrap: break-word; }
      .article-header-container .article-header-text .article-header-brand a {
        color: #ffffff;
        text-decoration: none; }

@media (max-width: 1024px) {
  .article-header-container .article-header-text .article-header-date {
    font-size: 18px;
    line-height: 23px; }
  .article-header-container .article-header-text .article-header-title {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 44px; } }

@media (max-width: 550px) {
  .article-header-container {
    height: 400px; }
    .article-header-container .article-header-text-before {
      height: 400px; }
    .article-header-container .article-header-text-after {
      height: 400px; }
    .article-header-container .article-header-text .article-header-date {
      font-size: 16px;
      line-height: 23px; }
    .article-header-container .article-header-text .article-header-title {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px; } }

.article-title {
  margin: 50px 0 10px;
  padding: 0 50px 15px; }
  .article-title h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 48px;
    margin: 0;
    padding: 0;
    word-wrap: break-word; }

@media (max-width: 600px) {
  .article-title h1 {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 38px; } }

@media (max-width: 400px) {
  .article-title h1 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px; } }

.blog-footer-container {
  background-color: #F9F3DF;
  width: 100%; }
  .blog-footer-container .blog-footer-top-section {
    height: 60px; }
  .blog-footer-container .blog-footer-social-section {
    align-items: center;
    align-content: center;
    background-color: #F7CA43;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 80px; }
    .blog-footer-container .blog-footer-social-section .social-icon {
      margin-right: 5px; }
  .blog-footer-container .blog-footer-bottom-section {
    align-items: center;
    align-content: center;
    background-color: #F7CA43;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 140px; }
    .blog-footer-container .blog-footer-bottom-section button {
      background-color: transparent;
      border-radius: 75px;
      border: 2px solid #274E53;
      color: #274E53;
      cursor: pointer;
      display: inline-block;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin: 0 0 25px 0;
      padding: 11px 19px 12px;
      text-align: center;
      text-decoration: none;
      touch-action: manipulation; }
      .blog-footer-container .blog-footer-bottom-section button:hover {
        background-color: #fffbf0;
        box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
        transform: scale(1.04); }
      .blog-footer-container .blog-footer-bottom-section button:focus {
        outline: none; }
      .blog-footer-container .blog-footer-bottom-section button:active {
        transform: scale(0.99); }

.article-hero-container {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 39px;
  min-height: 400px;
  width: 80%;
  position: relative; }
  .article-hero-container:hover {
    transform: scale(1.04); }
  .article-hero-container .article-hero-text-before {
    background-image: none;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .article-hero-container .article-hero-text-after {
    background-image: linear-gradient(to right, #1b1b1b, rgba(0, 0, 0, 0));
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .article-hero-container .article-hero-text {
    min-width: 100%;
    position: relative;
    z-index: 3; }
    .article-hero-container .article-hero-text .article-hero-date {
      color: #ffffff;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      margin: 50px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word; }
    .article-hero-container .article-hero-text .article-hero-title {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -2px;
      line-height: 48px;
      margin: 30px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word; }
    .article-hero-container .article-hero-text .article-hero-snippet {
      color: #ffffff;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      margin: 20px 15px 5px 15px;
      padding: 0 25px 30px;
      width: 40%;
      text-align: start;
      word-wrap: break-word; }

@media (max-width: 1024px) {
  .article-hero-container .article-hero-text .article-hero-snippet {
    font-size: 16px;
    line-height: 23px;
    margin: 30px 15px 5px 15px;
    width: auto; } }

@media (max-width: 550px) {
  .article-hero-container .article-hero-text .article-hero-date {
    font-size: 18px;
    line-height: 23px; }
  .article-hero-container .article-hero-text .article-hero-title {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px; }
  .article-hero-container .article-hero-text .article-hero-snippet {
    font-size: 16px;
    line-height: 23px;
    margin: 30px 15px 5px 15px;
    width: auto; } }

.route_404 {
  align-content: center;
  align-items: center;
  background-color: #F7CA43;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
  width: 100%; }
  .route_404 .title_404 {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 48px;
    margin: 15px;
    padding: 0 25px;
    text-align: center;
    word-wrap: break-word; }
  .route_404 .line_1_404, .route_404 .line_2_404 {
    color: #274E53;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    margin: 5px;
    padding: 0 25px;
    text-align: center;
    word-wrap: break-word; }
  .route_404 .line_3_404 {
    color: #274E53;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    padding: 0 25px;
    text-align: center;
    word-wrap: break-word; }

#root {
  height: 100%;
  width: 100%; }

/* autoprefixer grid: on */

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

