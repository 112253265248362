$primary-btn-color: #274E53;
$primary-btn-highlight-color: #1a6069;
$primary-text-color: #ffffff;
$secondary-btn-highlight-color: #fffbf0;
$secondary-text-color: #274E53;

.bypass-button-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;

  button {
    background-color: transparent;
    border-radius: 75px;
    border: 2px solid $primary-btn-color;
    color: $secondary-text-color;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500; // Medium
    line-height: 21px;
    margin: 0;
    padding: 11px 19px 12px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;

    &:hover {
      background-color: $secondary-btn-highlight-color;
      box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
      transform: scale(1.04);
    }

    &:focus {
      outline: none;
      //outline: 1px solid #fff;
      //outline-offset: -4px;
    }

    &:active {
      transform: scale(0.99);
    }

  }
}
