$engine-btn-color: #274E53;
$engine-btn-highlight-color: #1a6069;
$primary-text-color: #ffffff;
$dialog-mask-color: #274e53;

.rc-dialog-mask {
  background-color: $dialog-mask-color;
  opacity: 0.75;
}

.engine-center {
  display: flex;
  align-items: center;
  justify-content: center;

  >.rc-dialog {
    width: 98%;
    height: 97%;
    margin: 30px auto;

    >.rc-dialog-content {
      height: 100%;

      >.rc-dialog-close {
        color: #ffffff;
        right: 5px;
        top: 5px;
        opacity: 1;
        z-index: 99;
      }

      >.rc-dialog-body {
        height: 100%;
        padding: 0;
        opacity: 1;

        .googlemap-container {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

}

.engine-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;

  button {
    background-color: $engine-btn-color;
    border-color: transparent;
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(39, 78, 83, 0.52);
    color: $primary-text-color;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 500; // Medium
    line-height: 28px;
    margin: 30px;
    padding: 15px 28px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;

    &:hover {
      background-color: $engine-btn-highlight-color;
      box-shadow: 0 4px 8px rgba(39, 78, 83, 0.52);
      transform: scale(1.04);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.99);
    }

  }
}


@media (max-width: 600px) {
  .engine-center {
    >.rc-dialog {
      width: 100%;
      height: 100%;

      >.rc-dialog-content {
        border-radius: 0;

        >.rc-dialog-close {
          color: #757575;
          top: 10px;
        }

        >.rc-dialog-body {
          .googlemap-container {
            height: 100%;
            width: 100vw;
          }
        }
      }
    }
  }
}
