$email-text-color: #274E53;
$email-textbox-height: 64px;
$email-textbox-width: 460px;

.email-textbox-container {
  max-width: $email-textbox-width;
  width: 80%;

  .email-textbox-element {
    background-color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
    height: $email-textbox-height;
    position: relative;
    opacity: 0.86;
  }

  .email-textbox-input {
    border: 0;
    box-sizing: border-box;
    color: $email-text-color;
    float: left;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400; //Regular
    height: $email-textbox-height;
    line-height: 28px;
    padding: 15px 0 19px 20px;
    position: relative;
    opacity: 1;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $email-text-color;
    }
  }

}

@media (min-height: 300px ) and  (max-height: 500px) {
  .email-textbox-container {
    .email-textbox-element {
      height: 34px;
    }
    .email-textbox-input {
      font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
      line-height: 20px;
      height: 34px;
    }
  }
}
@media (min-height: 500px ) and  (max-height: 900px) {
  .email-textbox-container {
    .email-textbox-element {
      height: 34px;
    }
    .email-textbox-input  {
      font-size: calc(0.95rem + ((1vw - 4.8px) * 0.4167));
      line-height: 20px;
      height: 34px;
      padding: 15px 0 19px 15px;
    }
  }
}
