$primary-btn-color: #274E53;
$primary-btn-highlight-color: #1a6069;
$primary-text-color: #ffffff;
$secondary-btn-highlight-color: #fffbf0;
$secondary-text-color: #274E53;

.button-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;

  button {
    background-color: transparent;
    border-radius: 75px;
    border: 2px solid $primary-btn-color;
    color: $secondary-text-color;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500; // Medium
    line-height: 21px;
    margin: 0;
    padding: 11px 19px 12px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;

    &.btn-primary {
      background-color: $primary-btn-color;
      border-radius: 100px;
      box-shadow: 0 2px 4px rgba(39, 78, 83, 0.52);
      color: $primary-text-color;
      font-size: 18px;
      line-height: 28px;
      padding: 15px 28px;
    }

    &.btn-secondary {
      background-color: transparent;

      &:hover {
        background-color: $secondary-btn-highlight-color;
        box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
        transform: scale(1.04);
      }
    }

    &:hover {
      background-color: $primary-btn-highlight-color;
      box-shadow: 0 4px 8px rgba(39, 78, 83, 0.52);
      transform: scale(1.04);
    }

    &:focus {
      outline: none;
      //outline: 1px solid #fff;
      //outline-offset: -4px;
    }

    &:active {
      transform: scale(0.99);
    }

  }
}

@media (min-height: 300px ) and  (max-height: 500px) {
  .button-container {
    button {
      padding: 5px 15px 6px;
      margin: 5px;

      &.btn-primary {
        padding: 5px 15px;
      }
      &.btn-secondary {
        padding: 5px 15px;
      }
    }
  }
}
@media (min-height: 500px ) and  (max-height: 800px) {
  .button-container {
    button {
      padding: 5px 15px 6px;
      margin: 5px;

      &.btn-primary {
        padding: 8px 20px;
      }
      &.btn-secondary {
        padding: 8px 20px;
      }
    }
  }
}
