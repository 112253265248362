$text-color: #274E53;
$textbox-height: 64px;
$textbox-width: 460px;
$textbox-icon-width: 52px;

.textbox-container {
  max-width: $textbox-width;
  width: 80%;

  .textbox-element {
    background-color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
    height: $textbox-height;
    position: relative;
    opacity: 0.86;

    &.error {
      border-color: red;
    }
  }

  .textbox-icon {
    background-color: inherit;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    float: right;
    height: $textbox-height;
    padding: 17px 20px 23px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: $textbox-icon-width;

    &:focus {
      // outline: none;
    }
    &:active {
      transform: scale(0.99);
    }
  }

  .textbox-input {
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    color: $text-color;
    float: left;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400; //Regular
    height: $textbox-height;
    line-height: 28px;
    padding: 15px 0 19px 20px;
    position: relative;
    opacity: 1;
    width: $textbox-width - $textbox-icon-width - 20px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $text-color;
    }
  }

  .textbox-error-message {
    color: red;
    margin-top: 10px;
    margin-left: 5px;
  }

}

@media (min-height: 300px ) and  (max-height: 500px) {
  .textbox-container  {
    .textbox-element {
      height: 34px;
    }
    .textbox-input {
      font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
      line-height: 20px;
      height: 34px;
    }
  }
}
@media (min-height: 500px ) and  (max-height: 900px) {
  .textbox-container {
    .textbox-element {
      height: 34px;
    }
    .textbox-input {
      font-size: calc(0.95rem + ((1vw - 4.8px) * 0.4167));
      line-height: 20px;
      height: 34px;
      padding: 15px 0 19px 15px;
    }
  }
}
