.googlemap-container {
  height: 100%;
  width: 100%;
}

.street-view-container {
  position: relative;
  left: calc(100% / 2 - (90%/2));
  top: calc(-1 * (100vh / 2 + 225px));
  z-index: 3;

  #street-view-container {
    border-radius: 15px;
    height: 450px;
    position: absolute;
    width: 90%;
  }
}
