.article-header-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 400px;
  width: 100%;
  position: relative;

  .article-header-text-before {
    background-image: none;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .article-header-text-after {
    background-image: linear-gradient(to right, rgba(27,27,27,64), rgba(0,0,0,0));
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .article-header-text {
    min-width: 100%;
    position: relative;
    z-index: 3;

    .page-title-logo-container {
      margin: 40px 0 0 40px;
    }

    .article-header-date {
      color: #ffffff;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 18px;
      font-weight: 400; //Regular
      line-height: 28px;
      margin: 50px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word;
    }

    .article-header-title {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -2px;
      line-height: 48px;
      margin: 30px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word;
    }

    .article-header-brand {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 400; //Regular
      letter-spacing: -1.33px;
      line-height: 33px;
      margin: 20px 0 5px 15px;
      padding: 0 25px 30px;
      width: 40%;
      text-align: start;
      word-wrap: break-word;

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .article-header-container {
    .article-header-text {
      .article-header-date {
        font-size: 18px;
        line-height: 23px;
      }
      .article-header-title {
        font-size: 32px;
        letter-spacing: 0;
        line-height: 44px;
      }
    }
  }
}

@media (max-width: 550px) {
  .article-header-container {
    height: 400px;

    .article-header-text-before {
      height: 400px;
    }
    .article-header-text-after {
      height: 400px;
    }

    .article-header-text {
      .article-header-date {
        font-size: 16px;
        line-height: 23px;
      }
      .article-header-title {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
  }
}
