$button-color: #274E53;
$highlight-color: #1a6069;
$page-color: #F9F3DF;
$text-color: #274E53;

button {
  &.page-back-button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    color: $button-color;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;

    .svg-icon {
      path {
        fill: $button-color;
      }
    }

    .back-title {
      color: $text-color;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .back-subtitle {
      color: $text-color;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    &:hover {
      color: $highlight-color;
      transform: scale(1.05);

      .svg-icon {
        path {
          fill: $highlight-color;
        }
      }
    }

    &:focus {
      outline: none;
      //outline: 1px solid #fff;
      //outline-offset: -4px;
    }

    &:active {
      transform: scale(0.99);
    }
  }
}
