$btn-border-color: #274E53;
$btn-highlight-color: #f7ca43;
$text-color: #274E53;

.image-option-item {
  button {
    background-color: transparent;
    border: 4px solid rgba($btn-border-color, 0.14);
    border-radius: 70px;
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 38px;
    font-weight: 600; // Medium
    height: 141px;
    line-height: 52px;
    margin: 10px;
    padding: 0;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    width: 140px;

    &:hover {
      background-color: $btn-highlight-color;
      border: 4px solid $btn-border-color;
      transform: scale(1.04);
    }

    &:focus {
      outline: none;
    }

    &:active {
      border: 4px solid $btn-border-color;
      transform: scale(0.99);
    }
  }
}

@media (min-width: 180px ) and  (max-width: 480px){
  .image-option-item {
    button {
      height: 70px;
      width: 70px;
    }
  }
}
@media (min-width: 480px) and (max-height: 800px) {
  .image-option-item {
    button {
      height: 80px;
      width: 80px;
    }
  }
}
@media (min-width: 480px) and (min-height: 800px) {
  .image-option-item {
    button {
      height: 120px;
      width: 120px;
    }
  }
}
