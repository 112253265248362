$slider-rail-color: #43484d;
$slider-track-color: #ffffff;
$slider-handle-color: #274e53;
$slider-width: 460px;

$text-color: #274E53;

.slider-container {
  margin-top: 20px;
  max-width: $slider-width;
  width: 80%;

  h3 {
    color: $text-color;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -1.85px;
    line-height: 58px;
    margin: 20px 0 24px;
    text-align: center;
  }

  .rc-slider-mark {
    font-size: 16px;
  }

  .rc-slider-rail {
    background-color: $slider-rail-color;
    opacity: 0.1;
  }

  .rc-slider-track {
    background-color: $slider-track-color;
  }

  .rc-slider-handle {
    background-color: $slider-handle-color;
    border: 6px solid #ffffff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.17);
    height: 30px;
    margin-top: -13px;
    width: 30px;
  }
}

@media (max-width: 600px) {
  .slider-container {

    h3 {
      font-size: 36px;
      letter-spacing: -1.38px;
      line-height: 48px;
      margin: 20px 0 10px;
    }
  }

}

// Desktops
@media (min-width: 1281px) {

}
// Laptops, Desktops
@media (min-width: 1025px) and (max-width: 1280px) {
  .slider-container {
    h3 {
      font-size: 28px;
      letter-spacing: -1.38px;
      line-height: 34px;
      margin: 20px 0 10px;
    }
  }
}
// Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1024px) {
  .slider-container {
    h3 {
      font-size: 21px;
      letter-spacing: 0;
      line-height: 24px;
      margin: 10px 0 10px;
    }
  }
}
// Tablets, Ipads (landscape)
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .slider-container {
    h3 {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 15px 0 10px;
    }
  }
}
// Low Resolution Tablets, Mobiles (Landscape)
@media (min-width: 481px) and (max-width: 767px) {
  .slider-container {
    h3 {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 10px 0 10px;
    }
  }
}
// Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 480px) {
  .slider-container {
    h3 {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      margin: 10px 0 5px;
    }
  }
}
