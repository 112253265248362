$text-color: #274E53;
$search-width: 460px;
$search-icon-width: 52px;
$search-height: 64px;

.address-textbox-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .search-container {
    max-width: $search-width;
    width: 80%;

    .search-box {
      background-color: #ffffff;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
      height: $search-height;
      position: relative;
      opacity: 0.86;
    }

    .search-icon {
      background: transparent;
      border: none;
      cursor: pointer;
      float: right;
      height: $search-height;
      padding: 17px 20px 23px 0;
      position: absolute;
      right: 2px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: $search-icon-width;

      &:focus {
        // outline: none;
      }
      &:active {
        transform: scale(0.99);
      }
    }

    .search-input {
      border: 0;
      box-sizing: border-box;
      color: $text-color;
      display: block;
      float: left;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 20px;
      font-weight: 400; //Regular
      height: $search-height;
      line-height: 28px;
      padding: 15px 0 19px 20px;
      position: relative;
      opacity: 1;
      width: 100%;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $text-color;
      }
    }

    .search-input-autocomplete {
      border-bottom: rgba(255, 255, 255, 0.3);
      border-left: rgba(255, 255, 255, 0.3);
      border-right: rgba(255, 255, 255, 0.3);
      border-top: 1px solid #e6e6e6;
      box-shadow: 0 2px 4px rgba(191, 179, 39, 0.5);
      border-radius: 0 0 2px 2px;

      .autocomplete-item {
        text-align: left;
        background-color: #ffffff;
        cursor: pointer;
      }

      .autocomplete-item-active {
        background-color: #dedede !important;
      }
    }

  }

  .searchbox-error-message {
    color: red;
    margin-top: 10px;
    margin-left: 5px;
  }
}

@media (min-height: 300px ) and  (max-height: 500px) {
  .address-textbox-container {
    .search-container {
      .search-box {
        height: 34px;
      }
      .search-input {
        font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
        line-height: 20px;
        height: 34px;
      }
    }
  }
}
@media (min-height: 500px ) and  (max-height: 900px) {
  .address-textbox-container {
    .search-container {
      .search-box {
        height: 34px;
      }
      .search-input {
        font-size: calc(0.85rem + ((1vw - 4.8px) * 0.4167));
        line-height: 20px;
        height: 34px;
      }
    }
  }
}
