$contact-text-color: #274E53;
$contact-border-color: #9b9b9b;

.contact-container {
  align-content: center;
  align-items: center;
  border: 1px solid rgba($contact-border-color, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 144px;
  justify-content: center;
  margin: 9px;
  width: 418px;

  .contact-title {
    color: $contact-text-color;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 36px;
    margin-bottom: 13px;
  }

  .contact-email {
    color: $contact-text-color;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400; //Regular
    line-height: 28px;
  }

  .contact-phone {
    color: $contact-text-color;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400; //Regular
    line-height: 28px;
  }

  &:hover {
    transform: scale(1.04);
  }
}
