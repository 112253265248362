$engine-btn-color: #274E53;
$engine-btn-highlight-color: #fffbf0;
$primary-text-color: #ffffff;
$dialog-mask-color: #274e53;

.rc-dialog-mask {
  background-color: $dialog-mask-color;
  opacity: 0.75;
}

.solar-report-center {
  display: flex;
  align-items: center;
  justify-content: center;

  >.rc-dialog {
    height: 90%;
    margin: 30px auto;

    >.rc-dialog-content {
      background-color: transparent;
      height: 100%;

      >.rc-dialog-close {
        color: white;
        right: -25px;
        top: -5px;
      }

      >.rc-dialog-body {
        height: 100%;
        padding: 0;
        opacity: 1;
      }
    }
  }
}

.solar-report-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;

  button {
    background-color: transparent;
    border-radius: 56px;
    border: 2px solid $engine-btn-color;
    box-shadow: 0 2px 4px rgba(39, 78, 83, 0.52);
    color: $engine-btn-color;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 11px;
    font-weight: 500; // Medium
    float: right;
    line-height: 15.75px;
    margin: 30px;
    padding: 15px 28px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;

    &:hover {
      background-color: $engine-btn-highlight-color;
      box-shadow: 0 4px 8px rgba(39, 78, 83, 0.52);
      transform: scale(1.04);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.99);
    }

  }
}
