.article-title {
  margin: 50px 0 10px;
  padding: 0 50px 15px;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 48px;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
  }
}

@media (max-width: 600px) {
  .article-title {
    h1 {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 38px;
    }
  }
}

@media (max-width: 400px) {
  .article-title {
    h1 {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
    }
  }
}
