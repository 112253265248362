.map-annotation-container {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .map-annotation-dot {
    margin-top: 300px;
    background-color: #F7CA43;
    border-radius: 50%;
    box-shadow: 0 0 0 5px #fff, 0 0 0 8px rgba(247, 202, 67, 0.3);
    display: inline-block;
    height: 20px;
    width: 20px;
  }

  .map-annotation-text {
    margin-top: 300px;
    background-color: rgba(27, 27, 27, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    bottom: 170%;
    color: #fff;
    left: 50%;
    margin-left: -60px;
    padding: 5px 0;
    position: absolute;
    text-align: center;
    width: 120px;
    z-index: 8;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border: 5px solid transparent;
      border-top-color: rgba(27, 27, 27, 0.5);
    }
  }

}


