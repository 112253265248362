.article-hero-container {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 39px;
  min-height: 400px;
  width: 80%;
  position: relative;

  &:hover {
    transform: scale(1.04);
  }

  .article-hero-text-before {
    background-image: none;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .article-hero-text-after {
    background-image: linear-gradient(to right, rgba(27,27,27,64), rgba(0,0,0,0));
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .article-hero-text {
    min-width: 100%;
    position: relative;
    z-index: 3;

    .article-hero-date {
      color: #ffffff;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 18px;
      font-weight: 400; //Regular
      line-height: 28px;
      margin: 50px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word;
    }

    .article-hero-title {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -2px;
      line-height: 48px;
      margin: 30px 0 5px 15px;
      padding: 0 25px;
      text-align: start;
      word-wrap: break-word;
    }

    .article-hero-snippet {
      color: #ffffff;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 18px;
      font-weight: 400; //Regular
      line-height: 28px;
      margin: 20px 15px 5px 15px;
      padding: 0 25px 30px;
      width: 40%;
      text-align: start;
      word-wrap: break-word;
    }
  }
}

@media (max-width: 1024px) {
  .article-hero-container {
    .article-hero-text {
      .article-hero-snippet {
        font-size: 16px;
        line-height: 23px;
        margin: 30px 15px 5px 15px;
        width: auto;
      }
    }
  }
}

@media (max-width: 550px) {
  .article-hero-container {
    .article-hero-text {
      .article-hero-date {
        font-size: 18px;
        line-height: 23px;
      }
      .article-hero-title {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 28px;
      }
      .article-hero-snippet {
        font-size: 16px;
        line-height: 23px;
        margin: 30px 15px 5px 15px;
        width: auto;
      }
    }
  }
}
