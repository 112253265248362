.chip-component-container {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
}
