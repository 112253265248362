.route_404 {
  align-content: center;
  align-items: center;
  background-color: #F7CA43;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
  width: 100%;

  .title_404 {
    color: #274E53;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 48px;
    margin: 15px;
    padding: 0 25px;
    text-align: center;
    word-wrap: break-word;
  }

  .line_1_404, .line_2_404 {
    color: #274E53;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 24px;
    font-weight: 400; //Regular
    line-height: 28px;
    margin: 5px;
    padding: 0 25px;
    text-align: center;
    word-wrap: break-word;
  }

  .line_3_404 {
    color: #274E53;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400; //Regular
    line-height: 28px;
    margin: 0;
    padding: 0 25px;
    text-align: center;
    word-wrap: break-word;
  }
}
