.image-component-container {
  align-content: center;
  align-items: center;
  //background-image: radial-gradient(ellipse at center, #dedede 1%, rgb(249, 243, 223) 100%);
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.image-component-asset {
  object-fit: fill;
  overflow: hidden;
  height: 100%;
}
