$text-color: #274E53;
$highlight-color: #1a6069;

.page-title-container {
  display: flex;
  position: relative;
  width: 100%;

  .page-title {
    color: $text-color;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1.33px;
    padding: 15px 0 0 30px;
  }

  .page-back-btn-container {
    align-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;

    button {
      border: none;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0);
      color: $text-color;
      cursor: pointer;
      font-family: sans-serif;
      font-size: 1rem;
      line-height: 1;
      margin-left: -95px;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;

      .svg-icon {
        path {
          fill: $text-color;
        }
      }

      &:hover {
        color: $highlight-color;
        transform: scale(1.05);

        .svg-icon {
          path {
            fill: $highlight-color;
          }
        }
      }

      &:focus {
        outline: none;
      }

      &:active {
        transform: scale(0.99);
      }
    }

  }

}

