.helperText {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 150px;
    height: auto;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin: 0;
  }
}
