$dialog-background-color: #F9F3DF;
$dialog-button-color: #274E53;
$dialog-btn-highlight-color: #fffbf0;
$dialog-text-color: #274E53;
$dialog-mask-color: #274e53;

.dialog-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-dialog-mask {
  background-color: $dialog-mask-color;
  opacity: 0.75;
}

.rc-dialog-content {
  background-color: $dialog-background-color;

  .rc-dialog-close {
    color: $dialog-button-color;
  }

  .rc-dialog-body {
    color: $dialog-text-color;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 6px 30px 5px;
    opacity: 0.8;
  }
}

.rc-dialog-header {
  background: $dialog-background-color;
  border-bottom: none;
  padding: 9px 30px 0;

  .rc-dialog-title {
    color: $dialog-text-color;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1.33px;
    line-height: 48px;
  }
}

.rc-dialog-footer {
  border-top: none;
  height: 45px;

  button {
    background-color: transparent;
    border-radius: 56px;
    border: 2px solid $dialog-button-color;
    color: $dialog-text-color;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 11px;
    font-weight: 500; // Medium
    float: right;
    line-height: 15.75px;
    margin-right: 10px;
    padding: 8px 16px 10px 15px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;

    &:hover {
      background-color: $dialog-btn-highlight-color;
      box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
      transform: scale(1.04);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.99);
    }
  }
}

.dialog-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;

  button {
    background-color: transparent;
    border-radius: 75px;
    border: 2px solid $dialog-button-color;
    color: $dialog-text-color;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500; // Medium
    line-height: 21px;
    margin: 30px;
    padding: 11px 19px 12px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;

    &:hover {
      background-color: $dialog-btn-highlight-color;
      box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
      transform: scale(1.04);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.99);
    }

  }
}

@media (max-width: 600px) {
  .dialog-container {

    button {
      margin: 3px 0 5px;
    }
  }

}

@media (min-height: 300px ) and  (max-height: 500px) {
  .dialog-container {
    button {
      padding: 5px 15px 6px;
      margin: 5px;
    }
  }
}
@media (min-height: 500px ) and  (max-height: 800px) {
  .dialog-container {
    button {
      padding: 5px 15px 6px;
      margin: 5px;
    }
  }
}
