$primary-btn-color: #274E53;
$primary-btn-highlight-color: #1a6069;
$primary-text-color: #ffffff;
$secondary-btn-highlight-color: #fffbf0;
$secondary-text-color: #274E53;

.blog-footer-container {
  background-color: #F9F3DF;
  width: 100%;

  .blog-footer-top-section {
    height: 60px;
  }

  .blog-footer-social-section {
    align-items: center;
    align-content: center;
    background-color: #F7CA43;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 80px;

    .social-icon {
      margin-right: 5px;
    }
  }

  .blog-footer-bottom-section {
    align-items: center;
    align-content: center;
    background-color: #F7CA43;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 140px;

    button {
      background-color: transparent;
      border-radius: 75px;
      border: 2px solid $primary-btn-color;
      color: $secondary-text-color;
      cursor: pointer;
      display: inline-block;
      font-family: "Roboto", Arial, sans-serif;
      font-size: 14px;
      font-weight: 500; // Medium
      line-height: 21px;
      margin: 0 0 25px 0;
      padding: 11px 19px 12px;
      text-align: center;
      text-decoration: none;
      touch-action: manipulation;

      &:hover {
        background-color: $secondary-btn-highlight-color;
        box-shadow: 0 2px 2px rgba(39, 78, 83, 0.19);
        transform: scale(1.04);
      }

      &:focus {
        outline: none;
      }

      &:active {
        transform: scale(0.99);
      }
    }
  }
}
