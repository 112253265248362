.image-container-component {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  .image-container-component-before {
    background-image: none;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .image-container-component-after {
    background-color: #1B1B1B;
    opacity: 0.6;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .image-container-component-children {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    min-width: 100%;
    position: relative;
    z-index: 3;
  }
}
