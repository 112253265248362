.article-image-component {
  height: 500px;
  margin: 15px 50px;
  width: calc(100% - 100px);

  .article-image-asset {
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .article-image-component {
    height: 350px;
    margin: 15px 50px;
    width: calc(100% - 100px);
  }
}

@media (max-width: 600px) {
  .article-image-component {
    height: 200px;
    margin: 15px 50px;
    width: calc(100% - 100px);
  }
}
